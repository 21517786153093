// extracted by mini-css-extract-plugin
export var columnWrapper = "b4l_product_overview-module--columnWrapper--d0ca3";
export var dateline = "b4l_product_overview-module--dateline--a29e1";
export var heading = "b4l_product_overview-module--heading--a55ee";
export var headingLogo = "b4l_product_overview-module--headingLogo--49070";
export var headingWrapper = "b4l_product_overview-module--headingWrapper--31ae4";
export var heroImage = "b4l_product_overview-module--heroImage--a930d";
export var instance = "b4l_product_overview-module--instance--07df8";
export var latestnewsarticleheadline = "b4l_product_overview-module--latestnewsarticleheadline--800d0";
export var link = "b4l_product_overview-module--link--091f2";
export var newsArchives = "b4l_product_overview-module--newsArchives--88a4f";
export var photo = "b4l_product_overview-module--photo--89fa3";
export var photoCaption = "b4l_product_overview-module--photoCaption--63fd5";
export var photosWrapper = "b4l_product_overview-module--photosWrapper--1b675";
export var playerWrapper = "b4l_product_overview-module--playerWrapper--92b7a";
export var productOverviewWrapper = "b4l_product_overview-module--productOverviewWrapper--0a4f3";
export var reactPlayer = "b4l_product_overview-module--reactPlayer--38c73";
export var rightCaptions = "b4l_product_overview-module--rightCaptions--ac65c";
export var rightPhoto = "b4l_product_overview-module--rightPhoto--21459";
export var rightPhotoCaption = "b4l_product_overview-module--rightPhotoCaption--b629a";
export var subheading = "b4l_product_overview-module--subheading--379f8";
export var textWrapper = "b4l_product_overview-module--textWrapper--8b453";